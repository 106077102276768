import { Inject, Injectable, Optional } from '@angular/core';

import { BehaviorSubject, map, Observable } from 'rxjs';
import { Environment } from 'sick-environment';
import { ENVIRONMENT } from 'sick-environment';

import { DebugMessage } from '../models/debug-message';
import { DEBUG_MESSAGE_CONSUMER, DebugWriter } from '../tokens/debug-output.token';
import { SickNgLogger } from './sick-ng-logger';

@Injectable({
  providedIn: 'root',
})
export class SickNgLoggerService {
  private messages$ = new BehaviorSubject<DebugMessage[]>([]);

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    @Optional() @Inject(DEBUG_MESSAGE_CONSUMER) private loggingOutput: DebugWriter[]
  ) {}

  public get messages(): Observable<DebugMessage[]> {
    return this.messages$.pipe(map((messages) => messages.filter((msg) => msg.type <= this.env.LOG_LEVEL)));
  }

  getLogger(name: string): SickNgLogger {
    const logger = new SickNgLogger(name);

    logger.current$.subscribe((message) => {
      this.appendMessage(message);
    });

    return logger;
  }

  private appendMessage(message: DebugMessage) {
    this.messages$.next(this.messages$.value.concat([message]));
    if (this.loggingOutput) {
      this.loggingOutput.forEach((output) => output.write(message));
    }
  }
}
