<div class="backdrop">
  <div class="modal">
    <h4 class="title">
      {{ title }}
      <davinci-icon-ref class="close" [attr.src]="this.icons['CLOSE']" (click)="onCloseClicked()"></davinci-icon-ref>
    </h4>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
