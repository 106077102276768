import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import '@sick-davinci/core-elements';
import '@sick-davinci/dashboard-elements';

import { SickApiModule } from 'sick-api';
import { IconList, ICONS } from 'sick-base-theme';
import { SickDebugModule } from 'sick-debug';
import { ENVIRONMENT, Environment } from 'sick-environment';
import { AuthConfig, SickIdAuthConfigProvider, SickIdAuthModule } from 'sick-id-auth';

import { SickBaseAppComponent } from './components/sick-base-app/sick-base-app.component';
import { SickInstanceInfoComponent } from './components/sick-instance-info/sick-instance-info.component';
import { SickInstanceListComponent } from './components/sick-instance-list/sick-instance-list.component';
import { SickLoadingIndicatorComponent } from './components/sick-loading-indicator/sick-loading-indicator.component';
import { SickLoginComponent } from './components/sick-login/sick-login.component';
import { SickModalComponent } from './components/sick-modal/sick-modal.component';
import { DavinciBasicElementsModule } from './modules/davinci-basic-elements.module';
import { InstanceUrlPipe } from './pipes/instance-url/instance-url.pipe';
import { SickBaseAppRoutingModule } from './sick-base-app-routing.module';

class SickDefaultAuthConfigProvider extends SickIdAuthConfigProvider {
  constructor(private env: Environment) {
    super();
  }

  getConfig(): AuthConfig {
    const config: AuthConfig = {
      authority: this.env.oidc.authority,
      clientId: this.env.oidc.clientId,
      silentRenew: this.env.isLocal ? false : this.env.oidc.silentRenew === false ? false : true,
      secureRoutes: [this.env.apiUrl],
    };

    return config;
  }
}

const authConfigFactory = (ENVIRONMENT: Environment) => {
  return new SickDefaultAuthConfigProvider(ENVIRONMENT);
};

@NgModule({
  declarations: [
    SickBaseAppComponent,
    SickLoginComponent,
    SickLoadingIndicatorComponent,
    SickModalComponent,
    SickInstanceListComponent,
    SickInstanceInfoComponent,
    InstanceUrlPipe,
  ],
  imports: [
    DavinciBasicElementsModule,
    SickDebugModule,
    SickIdAuthModule.forRoot(),
    SickBaseAppRoutingModule,
    CommonModule,
    TranslateModule,
    SickApiModule,
  ],
  exports: [
    RouterModule,
    SickBaseAppComponent,
    SickModalComponent,
    SickLoadingIndicatorComponent,
    SickInstanceInfoComponent,
    SickInstanceListComponent,
    InstanceUrlPipe,
  ],
  providers: [
    { provide: SickIdAuthConfigProvider, useFactory: authConfigFactory, deps: [ENVIRONMENT] },
    { provide: ICONS, useValue: IconList },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SickBaseAppModule {}
