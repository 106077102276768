<form [formGroup]="instanceForm">
  <div class="form-field">
    <label>Please enter a title for your instance</label>
    <davinci-text-field label="title" name="title" formControlName="title"></davinci-text-field>
  </div>

  <div class="form-field">
    <label>Please select a device</label>
    <davinci-drop-down label="Device" formControlName="device">
      <davinci-option value="6d795306-0f50-4412-af2a-b83e0d78c236">DT-50 Top</davinci-option>
      <davinci-option value="FAKE-UUID-FOR-DT35">DT-35 Tray</davinci-option>
    </davinci-drop-down>
  </div>

  <div class="form-field">
    <label>Please select a property</label>

    <davinci-drop-down label="Property" formControlName="property">
      <davinci-option value="distanceToObject">
        distanceToObject <span style="font-size: 0.8rem">(recommended ✔️)</span>
      </davinci-option>
      <davinci-option value="switchingPoints">switchingPoints</davinci-option>
    </davinci-drop-down>
  </div>

  <div class="form-field">
    <label>Please select a data processing flow</label>

    <davinci-drop-down label="Flow" formControlName="flow">
      <davinci-option value="afada2d5-c32b-5068-8f76-420974f91509">
        Fill Level Calculation <span style="font-size: 0.8rem">(recommended ✔️)</span></davinci-option
      >
      <davinci-option value="66e995bc-4ce8-5f26-b551-11bf8a85ff31">Param Calculactor</davinci-option>
      <davinci-option value="3b40af24-0cf6-58ef-99d0-2a3b6d07191d">Device Status</davinci-option>
    </davinci-drop-down>

    <div class="field-group">
      <davinci-text-field label="Param 0%" formControlName="min"></davinci-text-field>
      <davinci-text-field label="Param 100%" formControlName="max"></davinci-text-field>
    </div>
  </div>

  <div class="actions">
    <davinci-button type="bare" (click)="onCancel()" [attr.disabled]="loading ? 'disabled' : null">
      Cancel
    </davinci-button>
    <davinci-button #submitbtn type="outline" (click)="onSubmit()" [attr.disabled]="!instanceForm.valid || loading">
      Create instance <davinci-spinner *ngIf="loading"></davinci-spinner>
    </davinci-button>
  </div>
</form>
