import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IconList, ICONS } from 'sick-base-theme';
import { SickNgLogger, SickNgLoggerService } from 'sick-debug';
import { ENVIRONMENT, Environment } from 'sick-environment';
import { SickIdAuthService } from 'sick-id-auth';

import { CustomFunction, HeaderEvent, HeaderFunction } from '../../models/models';

@Component({
  selector: 'sick-base-app',
  templateUrl: './sick-base-app.component.html',
  styleUrls: ['./sick-base-app.component.scss'],
})
export class SickBaseAppComponent implements OnInit {
  @Input()
  appName = 'IntegrationHub';

  @Input()
  hasSettings = false;

  @Output()
  buttonClicked: EventEmitter<HeaderEvent> = new EventEmitter();

  readonly languages = [
    { code: 'de', label: 'Deutsch', disabled: false },
    { code: 'en', label: 'English', disabled: false },
  ];
  language = 'en';
  @ViewChild('appMenu')
  appMenu!: ElementRef;

  @ViewChild('userMenu')
  userMenu!: ElementRef;

  copyrightYear = new Date().getFullYear();
  flyoutMenuVisible = false;
  debugPanel = false;
  isAuthenticated = false;
  logger: SickNgLogger;

  showDebugPanel = false;

  @Input()
  customFunctions: CustomFunction[] = [];

  private debugFunction: CustomFunction = {
    id: HeaderFunction.DEBUG,
    title: 'Debug',
    iconSrc: this.SICK_ICONS['DEBUG'],
  };

  constructor(
    private authService: SickIdAuthService,
    private debugService: SickNgLoggerService,
    @Inject(ENVIRONMENT) private env: Environment,
    @Inject(ICONS) private SICK_ICONS: IconList,
    private translateService: TranslateService,
    private renderer: Renderer2
  ) {
    this.logger = this.debugService.getLogger(this.constructor.name);
    this.debugPanel = this.env.debug;

    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((evt) => {
      this.language = evt.lang;
    });
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((isLoggedIn) => (this.isAuthenticated = isLoggedIn));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.authService.userData.subscribe((userData: any) => {
      if (userData) {
        this.logger.info('UserData {0} {1}', userData.name, JSON.stringify(userData));
        this.translateService.use(userData.locale);
      }
    });
  }

  onButtonClicked(evt: HeaderEvent) {
    switch (evt.detail?.id) {
      case HeaderFunction.DEBUG:
        this.showDebugPanel = !this.showDebugPanel;
        break;
      case 'std_user':
        return this.showFlyoutMenu(evt, this.userMenu);
      case 'std_language':
        return this.showFlyoutMenu(evt, this.appMenu);
      default:
        this.buttonClicked.emit(evt);
        break;
    }
  }

  private logout() {
    this.authService.logout();
  }

  hideFlyouts() {
    this.appMenu.nativeElement.open = false;
    this.userMenu.nativeElement.open = false;
    this.flyoutMenuVisible = false;
  }

  onLogout() {
    this.hideClickCatcher();
    this.logout();
  }

  onLanguageChange(lang: string) {
    this.hideClickCatcher();
    this.translateService.use(lang);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showFlyoutMenu($event: any, element: ElementRef) {
    element.nativeElement.open = !element.nativeElement.open;

    const rect = $event.detail.buttonElement.getBoundingClientRect();
    this.renderer.setStyle(element.nativeElement, 'left', `${rect.right - 30}px`);
    this.renderer.setStyle(element.nativeElement, 'top', `${rect.bottom + rect.height / 2}px`);

    this.flyoutMenuVisible = this.appMenu.nativeElement.open || this.userMenu.nativeElement.open;
  }

  private hideClickCatcher() {
    this.flyoutMenuVisible = false;
  }
}
