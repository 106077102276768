import { Component, Inject, Input, OnInit } from '@angular/core';

import { IconList } from 'sick-base-theme';
import { ICONS } from 'sick-base-theme';

import { DebugMessage } from '../../../models/debug-message';
import { SickNgLoggerService } from '../../../services/sick-ng-logger.service';

@Component({
  selector: 'sick-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss'],
})
export class DebugPanelComponent implements OnInit {
  @Input() show = false;
  messages: DebugMessage[] = [];
  showMessages = true;

  constructor(private loggerService: SickNgLoggerService, @Inject(ICONS) public ICON_LIST: IconList) {}

  ngOnInit(): void {
    this.loggerService.messages.subscribe((messages) => (this.messages = messages));
    // this.loggerService.messages.pipe(map((messages) => messages.pop())).subscribe(console.log);
  }
}
