import { Pipe, PipeTransform } from '@angular/core';

import { DebugMessageType } from '../models/debug-message';

@Pipe({
  name: 'debugMessageType',
})
export class DebugMessageTypePipe implements PipeTransform {
  transform(value: DebugMessageType): string {
    return DebugMessageType[value];
  }
}
