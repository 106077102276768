import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import {
  AngularDashboardElement,
  BindingHelper,
  Describe,
  FunctionExecutionService,
} from '@sick-curie/dashboard-builder';

import { Subscription } from 'rxjs';

import {
  WotBindingConfiguration,
  WotBindingService,
  WotBindingType,
} from '../../services/wot-binding/wot-binding.service';

@AngularDashboardElement({
  role: 'behavior',
  documentation: {
    // the name that'll be shown in the add element dialog
    name: 'WOT Binding',
    description: 'bind to a property of a given thing',
  },
})
@Component({
  selector: 'wot-binding',
  template: '',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class WotBindingComponent implements OnChanges, OnDestroy {
  // this is the property your binding has to write to on the parent
  @Input()
  property!: string;

  @Input()
  updatePeriodMs = 5000;

  @Input()
  thingUrl!: string;

  @Input()
  thingProperty!: string;

  @Describe('Function to apply to the device response: e.g. (point) => point.x')
  @Input()
  postProcessor!: string;

  @Input()
  thingDirectory!: string;

  private dataSub: Subscription | undefined;
  private helper: BindingHelper;

  // we need the element reference so we can access the parent and set the property
  constructor(
    private elRef: ElementRef<HTMLElement>,
    private wotBinding: WotBindingService,
    private functionExecutionService: FunctionExecutionService
  ) {
    this.helper = new BindingHelper(this.elRef, this.functionExecutionService);
  }

  ngOnChanges() {
    this.dataSub?.unsubscribe();

    const wotBindingConfiguration: WotBindingConfiguration = {
      affordance: this.thingProperty,
      updatePeriodMs: this.updatePeriodMs,
      bindingType: WotBindingType.PROPERTY,
      thingUrl: this.thingUrl,
      thingDirectory: this.thingDirectory,
    };

    const binding = this.wotBinding.bind(wotBindingConfiguration);

    this.dataSub = binding
      .pipe(this.helper.applyPostProcessor(this.postProcessor))
      .subscribe((value) => this.helper.setValue(this.property, value));
  }

  ngOnDestroy(): void {
    this.dataSub?.unsubscribe();
  }
}
