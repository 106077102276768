<div class="default-layout">
  <header>
    <davinci-core-header
      logo-title="Sick AG Waldkirch"
      (button-click)="onButtonClicked($event)"
      [attr.app-name]="appName"
      [attr.custom-functions]="customFunctions | json"
      language="true"
      user="true"
    >
    </davinci-core-header>

    <!-- Flyout menus for user, app menu buttons -->
    <div class="flyout-click-catcher" [class.active]="flyoutMenuVisible" (click)="hideFlyouts()"></div>

    <davinci-flyout-menu #userMenu id="user-menu" class="app-menu" external-control>
      <!-- <davinci-flyout-menu-item
        [label]="'TOOLBAR.PROFILE.MENU.SHOW_PROFILE' | translate"
        (menu-click)="onShowProfile()"
      ></davinci-flyout-menu-item>
      <davinci-flyout-menu-item
        [label]="'TOOLBAR.PROFILE.MENU.CHANGE_PASSWORD' | translate"
        (menu-click)="onChangePassword()"
      ></davinci-flyout-menu-item> -->
      <davinci-flyout-menu-item
        [label]="'TOOLBAR.PROFILE.MENU.LOGOUT' | translate"
        (menu-click)="onLogout()"
      ></davinci-flyout-menu-item>
    </davinci-flyout-menu>

    <davinci-flyout-menu #appMenu id="app-menu" class="app-menu" external-control>
      <davinci-flyout-menu-item
        *ngFor="let lang of languages"
        [label]="lang.label"
        [attr.disabled]="lang.disabled ? 'disabled' : null"
        [attr.icon]="lang.code === language ? 'toggle/radio_button_checked' : null"
        (menu-click)="onLanguageChange(lang.code)"
      ></davinci-flyout-menu-item>
    </davinci-flyout-menu>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <div id="sick-footer">
      <a href="https://www.sick.com/imprint" target="_blank" rel="noopener">{{ 'FOOTER.IMPRINT' | translate }}</a>
      <a href="https://www.sick.com/tac" target="_blank" rel="noopener">{{
        'FOOTER.TERMS_AND_CONDITIONS' | translate
      }}</a>
      <a href="https://www.sick.com/terms-of-use" target="_blank" rel="noopener">{{
        'FOOTER.TERMS_OF_USE' | translate
      }}</a>
      <a href="https://www.sick.com/dataprotection" target="_blank" rel="noopener">{{
        'FOOTER.DATA_PROTECTION' | translate
      }}</a>
      <a class="copyright">© {{ copyrightYear }} SICK AG</a>
    </div>
  </footer>
</div>
<sick-debug-panel *ngIf="debugPanel" [show]="showDebugPanel"></sick-debug-panel>
