<div *ngIf="isLoggedIn; else isLoggedOut">
  User is logged in with <pre>
    {{data | json}}
  </pre>
  <button (click)="logout()">Logout</button>
</div>
<ng-template #isLoggedOut>
  <div>User is logged out.</div>
  <button (click)="login()">Login</button>
</ng-template>
