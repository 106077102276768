import { Pipe, PipeTransform } from '@angular/core';

import { InstancePublic } from 'models';

@Pipe({
  name: 'instanceUrl',
})
export class InstanceUrlPipe implements PipeTransform {
  transform(instance: InstancePublic): string {
    const type = instance.appTemplate.id;
    switch (type) {
      case 'flm':
        return `/flm/i/${instance.id}`;
      case 'oee':
        return `/oee/i/${instance.id}`;
      default:
        throw new Error(`Unknown instance type ${type}`);
    }
  }
}
