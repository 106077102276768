<davinci-element-container heading="{{ instance.appTemplate.title }}" [class.is-loading]="loading">
  <div class="actions">
    <davinci-icon-ref
      *ngIf="!loading && allowDelete"
      [attr.src]="icons['DELETE']"
      class="icon icon--delete"
      (click)="onDeleteClick()"
    ></davinci-icon-ref>

    <davinci-spinner *ngIf="loading" class="icon icon--spinner"></davinci-spinner>
  </div>
  <div class="instance-info">
    <dl>
      <dt>{{ 'INSTANCE_INFO.TITLE' | translate }}</dt>
      <dd>{{ instance.title }}</dd>
      <dt>{{ 'INSTANCE_INFO.CREATED' | translate }}</dt>
      <dd>{{ instance.created | date : 'dd.MM.YYYY, HH:mm' }}h</dd>
    </dl>
    <davinci-button (click)="startInstance()" icon="action/launch">{{
      'INSTANCE_INFO.OPEN' | translate
    }}</davinci-button>
    <davinci-spinner
      *ngIf="redirecting"
      title="{{ 'INSTANCE_INFO.STOP_REDIRECTING' | translate }}"
      class="interactive-spinner"
      (click)="onStopTimerClick()"
      label="{{ 'INSTANCE_INFO.REDIRECTING' | translate }}"
    ></davinci-spinner>
  </div>
</davinci-element-container>
