<h2 class="title">{{ 'INSTANCES.TITLE' | translate }}</h2>
<!-- <davinci-button icon="action/cached" (click)="click()"></davinci-button>
<davinci-button [attr.icon-src]="icons['ADD']" (click)="showWizard = !showWizard"></davinci-button> -->
<!-- <sick-loading-indicator [show]="loading"></sick-loading-indicator> -->
<sick-instance-list template="flm" [autoRedirect]="true"></sick-instance-list>
<div class="new-instance-cta" *ngIf="allowCreation">
  <div class="no-instances">
    <h4>No instances found</h4>
    <p></p>
  </div>
  <div class="icons" (click)="showWizard = true">
    <h4 class="title">Create new instance</h4>
    <davinci-icon-ref class="instance" [attr.src]="icons['EMPTY_LIST']"></davinci-icon-ref>
    <davinci-icon-ref class="add" [attr.src]="icons['ADD']"></davinci-icon-ref>
  </div>
  <davinci-button (click)="showWizard = true" [attr.icon-src]="icons['ADD']" icon-position="prepend">
    Create instance
  </davinci-button>
</div>
<sick-modal *ngIf="showWizard" (close)="showWizard = !showWizard" title="Create new FLM instance">
  <app-instance-wizard (cancel)="showWizard = false" (instanceCreated)="onInstanceCreated()"></app-instance-wizard>
</sick-modal>
