export const IconList: IconList = {
  CLOSE:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSA2LjQxTDE3LjU5IDUgMTIgMTAuNTkgNi40MSA1IDUgNi40MSAxMC41OSAxMiA1IDE3LjU5IDYuNDEgMTkgMTIgMTMuNDEgMTcuNTkgMTkgMTkgMTcuNTkgMTMuNDEgMTJ6Ii8+PC9zdmc+DQo=',
  ADD: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik00IDZIMnYxNGMwIDEuMS45IDIgMiAyaDE0di0ySDRWNnptMTYtNEg4Yy0xLjEgMC0yIC45LTIgMnYxMmMwIDEuMS45IDIgMiAyaDEyYzEuMSAwIDItLjkgMi0yVjRjMC0xLjEtLjktMi0yLTJ6bS0xIDloLTR2NGgtMnYtNEg5VjloNFY1aDJ2NGg0djJ6Ii8+PC9zdmc+DQo=',
  EMPTY_LIST:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHdpZHRoPSIyNCIgeT0iMCIvPjxwYXRoIGQ9Ik0xOS45Nyw2LjQzTDEyLDJMNC4wMyw2LjQzTDkuMSw5LjI0QzkuODMsOC40OCwxMC44Niw4LDEyLDhzMi4xNywwLjQ4LDIuOSwxLjI0TDE5Ljk3LDYuNDN6IE0xMCwxMmMwLTEuMSwwLjktMiwyLTIgczIsMC45LDIsMnMtMC45LDItMiwyUzEwLDEzLjEsMTAsMTJ6IE0xMSwyMS40NEwzLDE3VjguMTRsNS4xMywyLjg1QzguMDQsMTEuMzEsOCwxMS42NSw4LDEyYzAsMS44NiwxLjI3LDMuNDMsMywzLjg3VjIxLjQ0eiBNMTMsMjEuNDR2LTUuNTdjMS43My0wLjQ0LDMtMi4wMSwzLTMuODdjMC0wLjM1LTAuMDQtMC42OS0wLjEzLTEuMDFMMjEsOC4xNEwyMSwxN0wxMywyMS40NHoiLz48L3N2Zz4NCg==',
  TOKEN:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iMjQiIHdpZHRoPSIyNCIgeT0iMCIvPjxwYXRoIGQ9Ik0xOS45Nyw2LjQzTDEyLDJMNC4wMyw2LjQzTDkuMSw5LjI0QzkuODMsOC40OCwxMC44Niw4LDEyLDhzMi4xNywwLjQ4LDIuOSwxLjI0TDE5Ljk3LDYuNDN6IE0xMCwxMmMwLTEuMSwwLjktMiwyLTIgczIsMC45LDIsMnMtMC45LDItMiwyUzEwLDEzLjEsMTAsMTJ6IE0xMSwyMS40NEwzLDE3VjguMTRsNS4xMywyLjg1QzguMDQsMTEuMzEsOCwxMS42NSw4LDEyYzAsMS44NiwxLjI3LDMuNDMsMywzLjg3VjIxLjQ0eiBNMTMsMjEuNDR2LTUuNTdjMS43My0wLjQ0LDMtMi4wMSwzLTMuODdjMC0wLjM1LTAuMDQtMC42OS0wLjEzLTEuMDFMMjEsOC4xNEwyMSwxN0wxMywyMS40NHoiLz48L3N2Zz4NCg==',
  DELETE:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik02IDE5YzAgMS4xLjkgMiAyIDJoOGMxLjEgMCAyLS45IDItMlY3SDZ2MTJ6TTE5IDRoLTMuNWwtMS0xaC01bC0xIDFINXYyaDE0VjR6Ii8+PC9zdmc+DQo=',
  LAUNCH:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOSAxOUg1VjVoN1YzSDVjLTEuMTEgMC0yIC45LTIgMnYxNGMwIDEuMS44OSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnYtN2gtMnY3ek0xNCAzdjJoMy41OWwtOS44MyA5LjgzIDEuNDEgMS40MUwxOSA2LjQxVjEwaDJWM2gtN3oiLz48L3N2Zz4NCg==',
  ARROW_DOWN:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTcuNDEgOC41OUwxMiAxMy4xN2w0LjU5LTQuNThMMTggMTBsLTYgNi02LTYgMS40MS0xLjQxeiIvPjwvc3ZnPg0K',
  LOGOUT:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xNyA3bC0xLjQxIDEuNDFMMTguMTcgMTFIOHYyaDEwLjE3bC0yLjU4IDIuNThMMTcgMTdsNS01ek00IDVoOFYzSDRjLTEuMSAwLTIgLjktMiAydjE0YzAgMS4xLjkgMiAyIDJoOHYtMkg0VjV6Ii8+PC9zdmc+DQo=',
  DEBUG:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0yMCA4aC0yLjgxYy0uNDUtLjc4LTEuMDctMS40NS0xLjgyLTEuOTZMMTcgNC40MSAxNS41OSAzbC0yLjE3IDIuMTdDMTIuOTYgNS4wNiAxMi40OSA1IDEyIDVjLS40OSAwLS45Ni4wNi0xLjQxLjE3TDguNDEgMyA3IDQuNDFsMS42MiAxLjYzQzcuODggNi41NSA3LjI2IDcuMjIgNi44MSA4SDR2MmgyLjA5Yy0uMDUuMzMtLjA5LjY2LS4wOSAxdjFINHYyaDJ2MWMwIC4zNC4wNC42Ny4wOSAxSDR2MmgyLjgxYzEuMDQgMS43OSAyLjk3IDMgNS4xOSAzczQuMTUtMS4yMSA1LjE5LTNIMjB2LTJoLTIuMDljLjA1LS4zMy4wOS0uNjYuMDktMXYtMWgydi0yaC0ydi0xYzAtLjM0LS4wNC0uNjctLjA5LTFIMjBWOHptLTYgOGgtNHYtMmg0djJ6bTAtNGgtNHYtMmg0djJ6Ii8+PC9zdmc+DQo=',
  EDIT: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0zIDE3LjI1VjIxaDMuNzVMMTcuODEgOS45NGwtMy43NS0zLjc1TDMgMTcuMjV6TTIwLjcxIDcuMDRjLjM5LS4zOS4zOS0xLjAyIDAtMS40MWwtMi4zNC0yLjM0Yy0uMzktLjM5LTEuMDItLjM5LTEuNDEgMGwtMS44MyAxLjgzIDMuNzUgMy43NSAxLjgzLTEuODN6Ii8+PC9zdmc+DQo=',
  SAVE: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xNyAzSDVjLTEuMTEgMC0yIC45LTIgMnYxNGMwIDEuMS44OSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMlY3bC00LTR6bS01IDE2Yy0xLjY2IDAtMy0xLjM0LTMtM3MxLjM0LTMgMy0zIDMgMS4zNCAzIDMtMS4zNCAzLTMgM3ptMy0xMEg1VjVoMTB2NHoiLz48L3N2Zz4NCg==',
  SETTINGS:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI0IDI0IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0cHgiPjxwYXRoIGQ9Ik0wLDBoMjR2MjRIMFYweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik0xOS4xNCwxMi45NGMwLjA0LTAuMywwLjA2LTAuNjEsMC4wNi0wLjk0YzAtMC4zMi0wLjAyLTAuNjQtMC4wNy0wLjk0bDIuMDMtMS41OGMwLjE4LTAuMTQsMC4yMy0wLjQxLDAuMTItMC42MSBsLTEuOTItMy4zMmMtMC4xMi0wLjIyLTAuMzctMC4yOS0wLjU5LTAuMjJsLTIuMzksMC45NmMtMC41LTAuMzgtMS4wMy0wLjctMS42Mi0wLjk0TDE0LjQsMi44MWMtMC4wNC0wLjI0LTAuMjQtMC40MS0wLjQ4LTAuNDEgaC0zLjg0Yy0wLjI0LDAtMC40MywwLjE3LTAuNDcsMC40MUw5LjI1LDUuMzVDOC42Niw1LjU5LDguMTIsNS45Miw3LjYzLDYuMjlMNS4yNCw1LjMzYy0wLjIyLTAuMDgtMC40NywwLTAuNTksMC4yMkwyLjc0LDguODcgQzIuNjIsOS4wOCwyLjY2LDkuMzQsMi44Niw5LjQ4bDIuMDMsMS41OEM0Ljg0LDExLjM2LDQuOCwxMS42OSw0LjgsMTJzMC4wMiwwLjY0LDAuMDcsMC45NGwtMi4wMywxLjU4IGMtMC4xOCwwLjE0LTAuMjMsMC40MS0wLjEyLDAuNjFsMS45MiwzLjMyYzAuMTIsMC4yMiwwLjM3LDAuMjksMC41OSwwLjIybDIuMzktMC45NmMwLjUsMC4zOCwxLjAzLDAuNywxLjYyLDAuOTRsMC4zNiwyLjU0IGMwLjA1LDAuMjQsMC4yNCwwLjQxLDAuNDgsMC40MWgzLjg0YzAuMjQsMCwwLjQ0LTAuMTcsMC40Ny0wLjQxbDAuMzYtMi41NGMwLjU5LTAuMjQsMS4xMy0wLjU2LDEuNjItMC45NGwyLjM5LDAuOTYgYzAuMjIsMC4wOCwwLjQ3LDAsMC41OS0wLjIybDEuOTItMy4zMmMwLjEyLTAuMjIsMC4wNy0wLjQ3LTAuMTItMC42MUwxOS4xNCwxMi45NHogTTEyLDE1LjZjLTEuOTgsMC0zLjYtMS42Mi0zLjYtMy42IHMxLjYyLTMuNiwzLjYtMy42czMuNiwxLjYyLDMuNiwzLjZTMTMuOTgsMTUuNiwxMiwxNS42eiIvPjwvc3ZnPg==',
  DEVICES:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIgb3BhY2l0eT0iLjEiLz48cGF0aCBkPSJNMjAgMTJjMC0yLjU0LTEuMTktNC44MS0zLjA0LTYuMjdMMTYgMEg4bC0uOTUgNS43M0M1LjE5IDcuMTkgNCA5LjQ1IDQgMTJzMS4xOSA0LjgxIDMuMDUgNi4yN0w4IDI0aDhsLjk2LTUuNzNDMTguODEgMTYuODEgMjAgMTQuNTQgMjAgMTJ6TTYgMTJjMC0zLjMxIDIuNjktNiA2LTZzNiAyLjY5IDYgNi0yLjY5IDYtNiA2LTYtMi42OS02LTZ6Ii8+PC9zdmc+',
};

export type IconList = Record<string, string>;
