/* eslint-disable @typescript-eslint/no-explicit-any */
import pupa from 'pupa';
import { BehaviorSubject, Subject } from 'rxjs';

import { DebugMessage, DebugMessageType } from '../models/debug-message';

export class SickNgLogger {
  name: string;
  messages$ = new BehaviorSubject<DebugMessage[]>([]);
  current$ = new Subject<DebugMessage>();

  constructor(name: string) {
    this.name = name;
  }
  debug(message: string, ...params: any[]) {
    this.appendMessage(message, params, DebugMessageType.DEBUG);
  }

  info(message: string, ...params: any[]) {
    this.appendMessage(message, params, DebugMessageType.INFO);
  }

  warn(message: string, ...params: any[]) {
    this.appendMessage(message, params, DebugMessageType.WARN);
  }

  error(message: string, ...params: any[]) {
    this.appendMessage(message, params, DebugMessageType.ERROR);
  }

  private appendMessage(message: string, parameters: any[], type: DebugMessageType) {
    const debugMessage: DebugMessage = {
      type,
      message,
      formattedMessage: pupa(message, parameters),
      parameters,
      timestamp: new Date(),
      initiator: this.name,
    };
    this.messages$.next(this.messages$.value.concat([debugMessage]));
    this.current$.next(debugMessage);
  }
}
