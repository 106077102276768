import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ThingDescriptionPublic, ThingDescriptionUpdateInput } from 'models';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

export interface ThingDirectoryListItem {
  thingId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThingDirectoryService {
  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/wot/things', this.httpClient);
  }

  list(instanceId: string) {
    return this.baseApiService.list<ThingDescriptionPublic>({ instanceId: instanceId });
  }

  read(thingId: string) {
    return this.baseApiService.read<ThingDescriptionPublic>(thingId);
  }

  update(thingId: string, thingData: ThingDescriptionUpdateInput) {
    return this.baseApiService.update<ThingDescriptionUpdateInput, ThingDescriptionPublic>(thingId, thingData);
  }
}
