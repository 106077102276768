import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BasicElementsFormLibModule } from '@sick-davinci/basic-elements-ng-form';
import '@sick-davinci/dashboard-elements';

import * as _ from 'lodash';
import { combineLatest, map } from 'rxjs';
import { SickApiModule } from 'sick-api';
import { SickBaseAppModule } from 'sick-base-app';
import { SickDashboardModule } from 'sick-dashboard';
import { ENVIRONMENT } from 'sick-environment';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { InstanceWizardComponent } from './components/instance-wizard/instance-wizard.component';

class IdaHttpTranslateloader implements TranslateLoader {
  private baseLoader: TranslateHttpLoader;
  private appLoader: TranslateHttpLoader;

  constructor(http: HttpClient) {
    this.baseLoader = new TranslateHttpLoader(http, 'assets/sick-base-app/i18n/');
    this.appLoader = new TranslateHttpLoader(http, 'assets/i18n/');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: string): any {
    return combineLatest([this.baseLoader.getTranslation(lang), this.appLoader.getTranslation(lang)]).pipe(
      map(([base, app]) => {
        return _.merge(base, app);
      })
    );
  }
}

class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return params.interpolateParams['default' as keyof object] || params.key;
    }
    return params.key;
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new IdaHttpTranslateloader(http);
}

export const NgxTranslateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
  defaultLanguage: 'en',
};

@NgModule({
  declarations: [AppComponent, DashboardComponent, InstanceWizardComponent],
  imports: [
    SickDashboardModule,
    SickBaseAppModule,
    SickApiModule,
    BasicElementsFormLibModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BasicElementsFormLibModule,
    TranslateModule.forRoot(NgxTranslateConfig),
  ],
  providers: [{ provide: ENVIRONMENT, useValue: environment }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
