<div>
  <davinci-spinner *ngIf="loading" label="loading things ...."></davinci-spinner>
  <div *ngIf="!loading">
    <davinci-drop-down
      (change)="onThingSelected($any($event).detail)"
      [data]="availableThings"
      [value]="selectedThing"
      label="Thing"
    >
    </davinci-drop-down>
  </div>
</div>
