<div>
  <p class="subtitle">Current binding</p>
  <div class="binding-settings">
    <davinci-text-field
      class="setting setting--url"
      [value]="binding.properties['thingUrl']"
      label="Thing URL"
      disabled
    >
    </davinci-text-field>
    <davinci-text-field
      class="setting setting--property"
      [value]="binding.properties['thingProperty']"
      label="Thing Property"
      disabled
    >
    </davinci-text-field>
  </div>
</div>

<div>
  <davinci-button (click)="showBinding = true">Rebind</davinci-button>
</div>

<div *ngIf="showBinding" class="binding-form">
  <div style="display: grid">
    <davinci-drop-down
      [data]="services"
      [value]="thingDirectoryServiceId"
      (change)="onThingDirectoryChanged($any($event).detail)"
      label="Thing Directory"
    >
    </davinci-drop-down>
  </div>

  <sick-thing-selector
    [thingDirectoryService]="thingDirectoryService!"
    (thingSelected)="onThingSelected($event)"
    [selectedThingUrl]="selectedThingUrl"
  ></sick-thing-selector>

  <div style="display: grid" *ngIf="selectedThing">
    <davinci-drop-down
      [linkToElement]="binding"
      linkToProperty="thingProperty"
      [data]="availableProperties"
      (change)="onThingPropertyChanged($any($event).detail)"
      label="Property"
    >
    </davinci-drop-down>
  </div>
  <div>
    <label>Post processor function</label>
    <davinci-text-area
      [placeholder]="postProcessorPlaceholder$ | async"
      [linkToElement]="binding"
      spellcheck="false"
      class="monospace"
      linkToProperty="postProcessor"
      (currentValueChange)="onPostProcessorChanged($any($event))"
      (input)="onPostProcessorChanged($any($event).detail)"
    ></davinci-text-area>
  </div>
  <davinci-text-field class="setting--interval" (change)="onIntervalChanged($any($event).detail)" [value]="binding.properties['updatePeriodMs']" label="Interval">
  </davinci-text-field>
</div>
