import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { FlowPublic } from 'models';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class FlowManagerApiService {
  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/staticflows', this.httpClient);
  }

  list() {
    return this.baseApiService.list<FlowPublic>();
  }
}
