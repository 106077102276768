import { Injectable } from '@angular/core';
import { BestEffortSingleQueryTimer } from '@sick-curie/dashboard-builder';

import { EMPTY, Observable, share, shareReplay } from 'rxjs';
import { SickNgLogger, SickNgLoggerService } from 'sick-debug';

import { ThingDirectoryRepositoryService } from '../thing-directory/thing-directory-repository.service';
import { WoTPropertyBindingService } from './wot-property-binding/wot-property-binding.service';

export enum WotBindingType {
  PROPERTY,
  EVENT,
  ACTIION,
}

export interface WotBindingConfiguration {
  bindingType: WotBindingType;
  affordance: string;
  updatePeriodMs: number;
  thingUrl: string;
  thingDirectory: string;
}

@Injectable({
  providedIn: 'root',
})
export class WotBindingService {
  private logger: SickNgLogger;
  private wotBindings: Map<string, Observable<string>> = new Map();

  constructor(
    private propBinding: WoTPropertyBindingService,
    private debugService: SickNgLoggerService,
    private thingDirectoryRepository: ThingDirectoryRepositoryService
  ) {
    this.logger = this.debugService.getLogger(this.constructor.name);
  }

  bind({ bindingType, affordance, updatePeriodMs, thingUrl, thingDirectory }: WotBindingConfiguration) {
    const thingDirectoryService = this.thingDirectoryRepository.load(thingDirectory);
    if (!thingDirectoryService) {
      throw new Error(`Could not load ThingDirectory Service with id ${thingDirectory}`);
    }

    if (bindingType === WotBindingType.PROPERTY) {
      const key = `${thingUrl}-${affordance}`;
      let binding = this.wotBindings.get(key);
      this.logger.debug('Adding binding with key {0}', key);

      if (!binding) {
        this.logger.debug('Binding not found! Creating new one');

        const thing$ = thingDirectoryService.load(thingUrl).pipe(shareReplay());

        const request = this.propBinding.bind(thing$, affordance, updatePeriodMs);
        this.logger.debug('updatePeriodMs: ' + updatePeriodMs);
        const bt = new BestEffortSingleQueryTimer(request, updatePeriodMs);
        binding = bt.periodicData().pipe(share());

        this.wotBindings.set(key, binding);
      }
      return binding;
    } else {
      return EMPTY;
    }
  }
}
