import { Component, OnInit } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { SickIdAuthService } from '../sick-id-auth-service/sick-id-auth.service';

@Component({
  selector: 'sick-id-auth',
  templateUrl: './sick-id-auth.component.html',
  styleUrls: ['./sick-id-auth.component.scss'],
})
export class SickIdAuthComponent implements OnInit {
  isLoggedIn = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = {};
  constructor(private authService: SickIdAuthService, private oidcSecurityService: OidcSecurityService) {}

  ngOnInit(): void {
    this.authService.isLoggedIn().subscribe((loggedIn) => (this.isLoggedIn = loggedIn));
    this.authService.userData.subscribe((userdata) => (this.data = userdata));
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    // eslint-disable-next-line no-console
    this.oidcSecurityService.logoff();
  }
}
