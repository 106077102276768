import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardBuilderModule } from '@sick-curie/dashboard-builder';

import { SickApiModule } from 'sick-api';
import { SickDebugModule } from 'sick-debug';

import { DashboardComponent } from './components/sick-dashboard/sick-dashboard.component';
import { ThingSelectorComponent } from './components/thing-selector/thing-selector.component';
import { WotBindingPropertiesComponent } from './components/wot-binding/wot-binding-properties/wot-binding-properties.component';
import { WotBindingComponent } from './components/wot-binding/wot-binding.component';
import { IdaThingDirectoryService } from './services/thing-directory/ida-thing-directory-service/ida-thing-directory.service';
import { THING_DIRECTORY_SERVICE } from './tokens/thing-directory-service.token';

@NgModule({
  declarations: [DashboardComponent, WotBindingPropertiesComponent, ThingSelectorComponent],
  imports: [
    BrowserModule,
    DashboardBuilderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    SickDebugModule,
    SickApiModule,
    TranslateModule,
  ],
  exports: [DashboardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: THING_DIRECTORY_SERVICE, useClass: IdaThingDirectoryService, multi: true },
    // { provide: THING_DIRECTORY_FILTER, useClass: IdaThingDirectoryFilterService },
  ],
})
export class SickDashboardModule {
  static REGISTER = [WotBindingComponent, WotBindingPropertiesComponent];
}
