import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ENVIRONMENT, Environment } from 'sick-environment';

import { DebugMessageComponent } from './components/debug/debug-message/debug-message.component';
import { DebugPanelComponent } from './components/debug/debug-panel/debug-panel.component';
import { SickConsoleLogger } from './consumer/sick-console-logger';
import { DavinciBasicElementsModule } from './modules/davinci-basic-elements.module';
import { DebugMessageTypePipe } from './pipes/debug-message-type.pipe';
import { SickNgLoggerService } from './services/sick-ng-logger.service';
import { DEBUG_MESSAGE_CONSUMER } from './tokens/debug-output.token';

const SickConsoleLoggerFactory = (env: Environment) => new SickConsoleLogger(env);

@NgModule({
  declarations: [DebugPanelComponent, DebugMessageComponent, DebugMessageTypePipe],
  imports: [CommonModule, DavinciBasicElementsModule],
  exports: [DebugPanelComponent],
  providers: [
    SickNgLoggerService,
    { provide: DEBUG_MESSAGE_CONSUMER, useFactory: SickConsoleLoggerFactory, deps: [ENVIRONMENT], multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SickDebugModule {}
