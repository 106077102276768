// davinci-basic-elements.module.ts
import { NgModule } from '@angular/core';
// Only import the components you actually use
import { DavinciIconComponent } from '@sick-ng/davinci-basic-elements';

// Create an array to reuse for imports and exports
const components = [DavinciIconComponent];

@NgModule({
  imports: components,
  exports: components,
})
export class DavinciBasicElementsModule {}
