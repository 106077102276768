import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, Observable, retry, switchMap, throwError } from 'rxjs';
import { SickNgLogger, SickNgLoggerService } from 'sick-debug';
import { ThingDescription } from 'wot-thing-description-types';

@Injectable({
  providedIn: 'root',
})
export class WoTPropertyBindingService {
  private logger: SickNgLogger;

  constructor(private httpClient: HttpClient, private loggerFactory: SickNgLoggerService) {
    this.logger = this.loggerFactory.getLogger(this.constructor.name);
  }

  bind(thing$: Observable<ThingDescription>, property: string, retryDelay = 1000) {
    const request = thing$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      switchMap((thing) => this.httpClient.get<any>(this.resolvePropertyFormHref(thing, property))),
      catchError((err) => {
        this.logger.error('Error binding property', err);
        return throwError(() => new Error(err));
      }),
      retry({
        count: 3,
        delay: retryDelay,
        resetOnSuccess: true,
      })
    );
    return request;
  }

  private resolvePropertyFormHref(thing: ThingDescription, property: string): string {
    if (!thing.properties) {
      const msg = `Thing ${thing.id} has no properties to bind to`;
      this.logger.error(msg);
      throw new Error(msg);
    }
    const prop = thing.properties[property];
    if (!prop) {
      const msg = `Thing ${thing.id} has no property ${property}`;
      this.logger.error(msg);
      throw new Error(msg);
    }
    return prop.forms[0].href;
  }
}
