import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { InstancePublic } from 'models';
import { InstanceManagerApiService } from 'sick-api';
import { IconList, ICONS } from 'sick-base-theme';

@Component({
  selector: 'sick-instance-info[instance]',
  templateUrl: './sick-instance-info.component.html',
  styleUrls: ['./sick-instance-info.component.scss'],
})
export class SickInstanceInfoComponent implements OnInit {
  @Output()
  instanceDeleteClick = new EventEmitter<InstancePublic>();

  @Output()
  startInstanceClick = new EventEmitter<InstancePublic>();

  @Output()
  stopTimerClick = new EventEmitter<void>();

  @Input() instance!: InstancePublic;

  @Input() redirecting = false;

  loading = false;

  allowDelete = false;

  constructor(private instanceManagerApi: InstanceManagerApiService, @Inject(ICONS) public icons: IconList) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  async onDeleteClick() {
    this.loading = true;
    this.instanceDeleteClick.emit(this.instance);
    this.instanceManagerApi.delete(this.instance.id).subscribe(() => {
      this.loading = false;
    });
  }

  onStopTimerClick() {
    this.stopTimerClick.emit();
  }

  startInstance() {
    this.startInstanceClick.emit(this.instance);
  }
}
