import { from, Observable } from 'rxjs';

import { IThingDirectoryFilter } from '../thing-directory-interface';

export class IdaDefaultThingDirectoryFilterService implements IThingDirectoryFilter {
  private replace = '';

  constructor(replace = '') {
    this.replace = replace;
  }

  appendFilter(url: URL): Observable<string> {
    const instance = window.location.pathname.replace(this.replace, '');
    url.searchParams.append('instanceId', instance);
    return from([url.toString()]);
  }
}
