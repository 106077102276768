import { Component, Input } from '@angular/core';

import { DebugMessage } from '../../../models/debug-message';

@Component({
  selector: 'sick-debug-message[message]',
  templateUrl: './debug-message.component.html',
  styleUrls: ['./debug-message.component.scss'],
})
export class DebugMessageComponent {
  @Input() message!: DebugMessage;

  showParams = false;
}
