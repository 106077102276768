import { NgModule } from '@angular/core';
import { InjectionToken } from '@angular/core';

import { Environment } from './sick-environment.interface';

export const ENVIRONMENT: InjectionToken<Environment> = new InjectionToken('ENVIRONMENT');

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class SickEnvironmentModule {}
