import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { SettingsPublic, SettingsUpdateInput } from 'models';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsManagerApiService {
  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/settings', this.httpClient);
  }

  read(id: string) {
    return this.baseApiService.read<SettingsPublic>(id);
  }

  update(id: string, settings: SettingsUpdateInput) {
    return this.baseApiService.update<SettingsUpdateInput, SettingsPublic>(id, settings);
  }
}
