<div *ngIf="loading; else dashboardWrapper">
  <div>{{ 'DASHBOARD.LOADING' | translate }}</div>
</div>
<ng-template #dashboardWrapper>
  <div class="actions" *ngIf="editable">
    <davinci-button
      (click)="toggleEditMode()"
      [iconSrc]="icons['EDIT']"
      title="{{ 'DASHBOARD.EDIT' | translate }}"
      *ngIf="!editMode"
    ></davinci-button>
    <davinci-button
      (click)="toggleEditMode()"
      [iconSrc]="icons['CLOSE']"
      title="{{ 'DASHBOARD.CLOSE_EDIT_MODE' | translate }}"
      *ngIf="editMode"
    ></davinci-button>
    <davinci-button
      (click)="onAddElementClicked()"
      [iconSrc]="icons['ADD']"
      title="{{ 'DASHBOARD.ADD_ELEMENT' | translate }}"
      *ngIf="editMode"
    >
    </davinci-button>
    <davinci-button
      (click)="saveDashboard()"
      [iconSrc]="icons['SAVE']"
      title="{{ 'DASHBOARD.SAVE' | translate }}"
      *ngIf="editMode"
    ></davinci-button>
    <davinci-spinner *ngIf="saving"></davinci-spinner>
  </div>
  <dashboard-builder-default-dashboard
    *ngIf="dashboard"
    #dbb
    [(rootNode)]="dashboard"
    [settings]="settings"
    [editMode]="editMode"
    class="dashboard-builder"
  >
  </dashboard-builder-default-dashboard>
</ng-template>
