import { Inject, Injectable } from '@angular/core';

import { THING_DIRECTORY_SERVICE } from '../../tokens/thing-directory-service.token';
import { IThingDirectoryService } from './thing-directory-interface';

@Injectable({
  providedIn: 'root',
})
export class ThingDirectoryRepositoryService {
  private thingDirectories = new Map<string, IThingDirectoryService>();

  constructor(@Inject(THING_DIRECTORY_SERVICE) thingDirectoryServices: IThingDirectoryService[]) {
    thingDirectoryServices.forEach((directory) => this.thingDirectories.set(directory.getUrl(), directory));
  }

  list() {
    return this.thingDirectories.entries();
  }

  load(id: string) {
    return this.thingDirectories.get(id);
  }
}
