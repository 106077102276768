import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SickIdAuthService } from '../sick-id-auth-service/sick-id-auth.service';

@Component({
  selector: 'lib-sick-id-auth-callback',
  templateUrl: './sick-id-auth-callback.component.html',
  styleUrls: ['./sick-id-auth-callback.component.scss'],
})
export class SickIdAuthCallbackComponent implements OnInit {
  constructor(private authService: SickIdAuthService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    this.authService.isLoggedIn().subscribe(async (isLoggedIn) => {
      if (isLoggedIn) {
        await this.router.navigateByUrl(localStorage.getItem('savedRoute') as string);
      }
    });
  }
}
