<div class="debug-panel" [ngClass]="{ open: showMessages }" *ngIf="show">
  <div class="title" (click)="showMessages = !showMessages">
    <span>Debug Panel</span>
    <davinci-icon-ref [attr.src]="ICON_LIST['ARROW_DOWN']" class="toggle"></davinci-icon-ref>
  </div>
  <div class="wrapper" *ngIf="showMessages">
    <div class="messages">
      <sick-debug-message *ngFor="let msg of messages.reverse()" [message]="msg"> </sick-debug-message>
    </div>
  </div>
</div>
