import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SickLoginComponent } from './components/sick-login/sick-login.component';

const routes: Routes = [{ title: 'Login', path: 'login', component: SickLoginComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SickBaseAppRoutingModule {}
