import { HttpClient } from '@angular/common/http';

export class BaseApiService {
  basePath = '';

  constructor(basePath: string, private httpClient: HttpClient) {
    this.basePath = basePath;
  }

  create<I, T>(resourceInput: I) {
    return this.httpClient.post<T>(`${this.basePath}`, resourceInput);
  }

  read<T>(resourceId: string) {
    return this.httpClient.get<T>(`${this.basePath}/${resourceId}`);
  }

  update<U, T>(resourceId: string, resourceUpdateInout: U) {
    return this.httpClient.put<T>(`${this.basePath}/${resourceId}`, resourceUpdateInout);
  }

  delete(resourceId: string) {
    return this.httpClient.delete(`${this.basePath}/${resourceId}`);
  }

  list<T>(filter?: { [key: string]: string }) {
    const url = new URL(this.basePath);
    if (filter) {
      for (const key in filter) {
        url.searchParams.set(key, filter[key]);
      }
    }

    return this.httpClient.get<T[]>(url.toString());
  }
}
