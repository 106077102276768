import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { InstanceInput, InstancePublic, InstanceUpdateInput } from 'models';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

export type TemplateFilter = 'flm' | 'oee';

@Injectable({
  providedIn: 'root',
})
export class InstanceManagerApiService {
  instances = new BehaviorSubject<InstancePublic[]>([]);

  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/instances', this.httpClient);
  }

  list(templateFilter: TemplateFilter | null = null) {
    let filter = undefined;
    if (templateFilter) {
      filter = { template: templateFilter };
    }
    const observable = this.baseApiService.list<InstancePublic>(filter).pipe(shareReplay(1));
    observable.subscribe((result) => {
      this.instances.next(result);
    });
    return observable;
  }

  read(instanceId: string) {
    return this.baseApiService.read<InstancePublic>(instanceId);
  }

  delete(instanceId: string) {
    const observable = this.baseApiService.delete(instanceId).pipe(shareReplay(1));

    observable.subscribe(() => {
      const oldResources = this.instances?.value;
      this.instances?.next(oldResources.filter((instance: InstancePublic) => instance.id != instanceId));
    });

    return observable;
  }

  create(instance: InstanceInput) {
    const observable = this.baseApiService.create<InstanceInput, InstancePublic>(instance).pipe(shareReplay(1));

    observable.subscribe((instance: InstancePublic) => {
      this.instances?.next(this.instances.value.concat(instance));
    });

    return observable;
  }

  update(instanceId: string, instanceUpdateInput: InstanceUpdateInput) {
    const observable = this.baseApiService
      .update<InstanceUpdateInput, InstancePublic>(instanceId, instanceUpdateInput)
      .pipe(shareReplay(1));

    observable.subscribe((instance: InstancePublic) => {
      const oldResources = this.instances?.value;
      this.instances?.next(
        oldResources.filter((instance: InstancePublic) => instance.id != instanceId).concat(instance)
      );
    });

    return observable;
  }
}
