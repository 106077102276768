import { Component, Input } from '@angular/core';

@Component({
  selector: 'sick-loading-indicator',
  templateUrl: './sick-loading-indicator.component.html',
  styleUrls: ['./sick-loading-indicator.component.scss'],
})
export class SickLoadingIndicatorComponent {
  @Input() show = false;
  @Input() speed = '10s';
}
