import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { AngularPropertiesElement, SpecializedBindingPropertiesComponent } from '@sick-curie/dashboard-builder';

import { BehaviorSubject, debounceTime, Observable } from 'rxjs';
import { SickNgLogger, SickNgLoggerService } from 'sick-debug';
import { ThingDescription } from 'wot-thing-description-types';

import { IThingDirectoryService } from '../../../services/thing-directory/thing-directory-interface';
import { ThingDirectoryRepositoryService } from '../../../services/thing-directory/thing-directory-repository.service';
import { WotBindingConfiguration } from '../../../services/wot-binding/wot-binding.service';

@AngularPropertiesElement
@Component({
  selector: 'wot-binding-properties',
  templateUrl: './wot-binding-properties.component.html',
  styleUrls: ['./wot-binding-properties.component.scss'],
})
export class WotBindingPropertiesComponent extends SpecializedBindingPropertiesComponent implements OnChanges {
  private logger: SickNgLogger;

  readonly postProcessorPlaceholder$ = new BehaviorSubject<string>('e.g. (point) => point.x');
  readonly postProcessorInput$ = new BehaviorSubject<string | undefined>(undefined);
  readonly postProcessor$: Observable<string | undefined> = this.postProcessorInput$.pipe(debounceTime(400));

  services: { label: string; value: string }[] = [];
  thingDirectoryService: IThingDirectoryService | null = null;
  thingDirectoryServiceId: string | null = null;
  availableProperties: { label: string; value: string }[] = [];
  showBinding = false;
  selectedThing: ThingDescription | null = null;
  selectedThingUrl: string | null = null;
  selectedThingProperty: string | null = null;

  constructor(
    private sickNgLogger: SickNgLoggerService,
    private thingDirectoryRepository: ThingDirectoryRepositoryService
  ) {
    super();
    this.logger = this.sickNgLogger.getLogger(this.constructor.name);
    for (const [id, service] of this.thingDirectoryRepository.list()) {
      this.services.push({ label: service.getName(), value: id });
    }
    if (this.services.length === 0) {
      throw new Error('No Thing Directories defined');
    }
    this.setFormReady(false);
  }

  onThingDirectoryChanged(thingDirectoryId: string) {
    this.logger.debug('onThingDirectoryChanged {0}', thingDirectoryId);
    this.thingDirectoryService = this.thingDirectoryRepository.load(thingDirectoryId) as IThingDirectoryService;
    this.selectedThing = null;
    this.propertyChange.emit({ property: 'thingDirectory', value: thingDirectoryId });
    this.propertyChange.emit({ property: 'thingProperty', value: '' });
  }

  onThingSelected(thing: ThingDescription) {
    this.selectedThing = thing;
    if (this.thingDirectoryService) {
      this.thingDirectoryService.getThingUrl(thing).subscribe((url) => {
        if (url && thing.properties) {
          this.logger.debug(
            'Thing {0} with props {1} and url {2}',
            thing.id,
            Object.keys(thing.properties).join(', '),
            url
          );
          this.propertyChange.emit({ property: 'thingUrl', value: url });
          this.availableProperties = Object.keys(thing.properties).map((prop) => ({ label: prop, value: prop }));
          const currentProp = this.binding.properties['thingProperty'];
          const propFound = this.availableProperties.find((prop) => prop.value === currentProp);

          if (propFound) {
            this.propertyChange.emit({ property: 'thingProperty', value: propFound.value });
            this.setFormReady(true);
          }
        }
      });
    }
  }

  onPostProcessorChanged(postProcessor: string): void {
    this.postProcessorInput$.next(postProcessor);
  }

  onThingPropertyChanged(property: string) {
    this.logger.debug('Thing property changed {0}', property);
    this.setFormReady(true);
  }

  onIntervalChanged(interval: string) {
    this.propertyChange.emit({ property: 'updatePeriodMs', value: parseInt(interval) });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['binding']) {
      const prop = changes['binding'].currentValue['properties'] as WotBindingConfiguration;
      const thingDirectoryId = prop.thingDirectory ? prop.thingDirectory : this.services[0].value;
      this.thingDirectoryService = this.thingDirectoryRepository.load(thingDirectoryId) as IThingDirectoryService;
      this.thingDirectoryServiceId = this.thingDirectoryService.getUrl();
      this.selectedThingUrl = prop.thingUrl;
      this.propertyChange.emit({ property: 'thingDirectory', value: thingDirectoryId });
    }
  }
}
