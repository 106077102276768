import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SickNgLogger, SickNgLoggerService } from 'sick-debug';
import { ThingDescription } from 'wot-thing-description-types';

import { IThingDirectoryService } from '../../services/thing-directory/thing-directory-interface';

export interface ThingSelectionEvent {
  thing: ThingDescription;
}

@Component({
  selector: 'sick-thing-selector',
  templateUrl: './thing-selector.component.html',
  styleUrls: ['./thing-selector.component.scss'],
})
export class ThingSelectorComponent implements OnChanges {
  private logger: SickNgLogger;
  selectedThing: string | null = null;

  @Input()
  thingDirectoryService!: IThingDirectoryService;

  @Input()
  selectedThingUrl: string | null = null;

  @Output()
  thingSelected = new EventEmitter<ThingDescription>();

  availableThings: { label: string; value: string; thing: ThingDescription }[] = [];
  loading = false;

  constructor(private loggerService: SickNgLoggerService) {
    this.logger = this.loggerService.getLogger(this.constructor.name);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.logger.debug('SimpleChanges {0}', simpleChanges);
    this.loading = true;
    this.availableThings = [];
    this.thingDirectoryService = simpleChanges['thingDirectoryService'].currentValue;
    this.logger.debug('Discover things from {0}', this.thingDirectoryService.getUrl());
    this.thingDirectoryService.discover().subscribe((things) => {
      this.availableThings = this.mapThings(things);
      this.logger.debug('Discovered {0}', things.map((t) => t.id).join(','));
      if (this.selectedThingUrl) {
        this.thingDirectoryService.load(this.selectedThingUrl).subscribe((thing) => this.selectThing(thing));
      } else {
        this.selectThing(things[0]);
      }
    });
  }

  onThingSelected(thingId: string) {
    this.logger.debug('New thing selected {0}', thingId);
    const thingEntry = this.findThingEntry(thingId);
    if (thingEntry) {
      this.thingSelected.emit(thingEntry.thing);
    }
  }

  private selectThing(thing: ThingDescription) {
    if (thing && thing.id) {
      this.selectedThing = thing.id;
    } else {
      this.selectedThing = this.availableThings[0].value;
    }
    this.onThingSelected(this.selectedThing);
    this.loading = false;
  }

  private mapThings(things: ThingDescription[]) {
    return things
      .map((thing) => ({
        label: `${thing.title} (${thing.id})`,
        _sortLabel: `${thing.title} (${thing.id})`.toLowerCase(),
        value: thing.id ?? '',
        // selected: thing.id === selected,
        thing,
      }))
      .sort((a, b) => (a._sortLabel < b._sortLabel ? -1 : a._sortLabel > b._sortLabel ? 1 : 0));
  }

  private findThingEntry(id: string) {
    return this.availableThings.find((thing) => thing.value === id);
  }
}
