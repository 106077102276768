// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from 'sick-environment';

export const environment: Partial<Environment> = {
  production: false,
  apiUrl: process.env['NG_APP_API_URL'],
  stage: process.env['NG_APP_STAGE'],
  isLocal: process.env['NG_APP_IS_LOCAL'] === 'true' ? true : false,
  debug: true,
  LOG_LEVEL: 4,
  oidc: {
    authority: 'https://id.sick.com/auth/realms/sickservices',
    clientId: 'IntegrationHub',
    silentRenew: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
