import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  DashboardCompactType,
  DashboardDisplayGrid,
  DashboardDisplayType,
  DashboardNewTilePosition,
  DashboardSettings,
  DefaultDashboardComponent,
  RootNode,
} from '@sick-curie/dashboard-builder';

import { DashboardApiService } from 'sick-api';
import { IconList, ICONS } from 'sick-base-theme';

@Component({
  selector: 'sick-dashboard',
  templateUrl: './sick-dashboard.component.html',
  styleUrls: ['./sick-dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit, OnInit {
  // the #dbb reference from the html
  @ViewChild('dbb') dbb!: DefaultDashboardComponent;

  @Input()
  instanceId = '';
  @Input()
  dashboardId: string | null = null;
  @Input()
  editable = false;

  dashboard: RootNode | null = null;
  loading = true;
  saving = false;

  // the settings
  settings: DashboardSettings = {
    width: 1800,
    height: 2000,
    displayType: DashboardDisplayType.Auto,
    newTilePosition: DashboardNewTilePosition.Auto,
    gridConfig: {
      compactType: DashboardCompactType.None,
      displayGrid: DashboardDisplayGrid.OnDragAndResize,
      outerMarginLeft: 0,
      outerMarginRight: 0,
    },
    generalPalette: 'primary', // 'primary' / 'secondary',
  };

  // whether the dashboard is in edit mode (true) or read-only mode (false)
  editMode = false;

  constructor(private dashboardApiService: DashboardApiService, @Inject(ICONS) public icons: IconList) {}

  ngOnInit(): void {
    if (this.dashboardId) {
      this.dashboardApiService.read(this.dashboardId).subscribe((item) => {
        this.dashboard = item.dashboard;
        this.loading = false;
      });
    } else if (this.instanceId) {
      this.dashboardApiService.loadByInstance(this.instanceId).subscribe((item) => {
        this.dashboard = item.dashboard;
        this.dashboardId = item.dashboardId;
        this.loading = false;
      });
    }
  }

  // since we're referencing a view child, the variable dbb is only
  // available after the view has been initialized
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngAfterViewInit(): void {}

  onAddElementClicked(): void {
    // the function will take care of adding the element to the dashboard,
    // but we'll still be notified since our binding to rootNode is bidirectional
    this.dbb.openAddElementDialog();
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  saveDashboard() {
    if (this.dashboardId) {
      this.saving = true;
      this.dashboardApiService.update(this.dashboardId, this.dbb.rootNode).subscribe(() => {
        this.saving = false;
      });
    }
  }
}
