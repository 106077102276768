export enum HeaderFunction {
  SETTINGS = 'app_settings',
  DEBUG = 'app_debug',
}

export interface CustomFunction {
  id: HeaderFunction | string;
  title: string;
  iconSrc: string;
}

export type HeaderEvent = Event & { detail?: { id: string } };
