import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { InstancePublic } from 'models';
import { Observable, Subscription, timer } from 'rxjs';
import { InstanceManagerApiService, TemplateFilter } from 'sick-api';

@Component({
  selector: 'sick-instance-list',
  templateUrl: './sick-instance-list.component.html',
  styleUrls: ['./sick-instance-list.component.scss'],
})
export class SickInstanceListComponent implements OnInit {
  @Input()
  template: TemplateFilter | null = null;

  @Input()
  autoRedirect = false;

  @Output()
  instancesLoaded: EventEmitter<InstancePublic[]> = new EventEmitter();

  instances: InstancePublic[] = [];
  redirecting = false;
  redirectTimer: Observable<0> | undefined;
  timerSubscription: Subscription | undefined;

  constructor(private instanceManagerApi: InstanceManagerApiService) {
    this.instanceManagerApi.instances.subscribe((instances) => {
      if (this.autoRedirect && instances.length === 1) {
        this.redirectToAppInstance(instances[0]);
      }
      this.instances = instances;
      this.instancesLoaded.emit(instances);
    });
  }

  ngOnInit(): void {
    this.instanceManagerApi.list(this.template).subscribe();
  }

  redirectToAppInstance(instance: InstancePublic) {
    this.redirecting = true;
    this.startTimer(instance);
  }

  startTimer(instance: InstancePublic) {
    this.redirectTimer = timer(3000);
    this.timerSubscription = this.redirectTimer.subscribe(() => {
      this.redirecting = false;
      this.startInstance(instance);
    });
  }

  stopTimer() {
    this.timerSubscription?.unsubscribe();
    this.redirecting = false;
  }

  startInstance(instance: InstancePublic) {
    this.stopTimer();
    const newHref = `${window.location.protocol}//${window.location.host}/flm/i/${instance.id}`;
    window.location.href = newHref;
  }
}
