import { Injectable } from '@angular/core';

import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { BehaviorSubject, filter } from 'rxjs';

interface UserData {
  sub: string;
  email_verified?: boolean;
  roles: Array<string>;
  name: string;
  preferred_username?: string;
  locale: string;
  given_name?: string;
  family_name?: string;
  email?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SickIdAuthService {
  private loggedIn = new BehaviorSubject(false);
  userData = new BehaviorSubject(null);
  token = new BehaviorSubject<string | null>(null);

  constructor(private oidcSecurityService: OidcSecurityService, private eventService: PublicEventsService) {
    this.oidcSecurityService.checkAuth().subscribe(({ accessToken, isAuthenticated, userData }) => {
      this.loggedIn.next(isAuthenticated);
      this.userData.next(userData);
      this.token.next(accessToken);
    });
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe((result) => this.loggedIn.next(result.value.isAuthenticated));
  }

  public logout() {
    this.oidcSecurityService.logoff();
  }

  public login() {
    this.oidcSecurityService.authorize();
  }

  public isLoggedIn() {
    return this.loggedIn;
  }

  public getAccessToken() {
    return this.oidcSecurityService.getAccessToken();
  }

  public hasRole(role: string): boolean {
    if (this.userData) {
      const data = this.userData.value as unknown as UserData;
      return data?.roles.find((userrole) => userrole === role) !== undefined;
    } else {
      return false;
    }
  }
}
