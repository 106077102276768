import { NgModule } from '@angular/core';

import { IconList } from './tokens/icon-list';
import { ICONS } from './tokens/icon-list.token';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    {
      provide: ICONS,
      useValue: IconList,
    },
  ],
})
export class SickBaseThemeModule {}
