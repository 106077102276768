import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';

import { catchError, Observable, of, shareReplay, throwError } from 'rxjs';
import { SickNgLogger, SickNgLoggerService } from 'sick-debug';
import { ENVIRONMENT, Environment } from 'sick-environment';
import { ThingDescription } from 'wot-thing-description-types';

import { THING_DIRECTORY_FILTER } from '../../../tokens/thing-directory-filter-service.token';
import { IThingDirectoryFilter, IThingDirectoryService } from '../thing-directory-interface';

@Injectable({ providedIn: 'root' })
export class IdaThingDirectoryService implements IThingDirectoryService {
  private baseUrl: string;
  private basePath = '/things';
  private discoveryUrl: string;
  private thingUrl: string;
  private logger: SickNgLogger;

  constructor(
    @Inject(ENVIRONMENT) env: Environment,
    private httpClient: HttpClient,
    private loggerService: SickNgLoggerService,
    @Optional() @Inject(THING_DIRECTORY_FILTER) private filter: IThingDirectoryFilter
  ) {
    this.logger = this.loggerService.getLogger(this.constructor.name);
    this.baseUrl = env.apiUrl + '/wot';
    this.discoveryUrl = `${this.baseUrl}${this.basePath}`;
    this.thingUrl = `${this.baseUrl}${this.basePath}`;

    if (this.filter) {
      this.filter.appendFilter(new URL(this.discoveryUrl)).subscribe((url) => {
        this.discoveryUrl = url;
      });
    }
  }

  load(thingURl: string) {
    return this.httpClient.get<ThingDescription>(thingURl).pipe(catchError((err) => this.handleError(err)));
  }

  getUrl(): string {
    return this.baseUrl;
  }

  getName(): string {
    return 'IDA ThingDirectoryService';
  }

  discover(): Observable<ThingDescription[]> {
    return this.httpClient.get<ThingDescription[]>(this.discoveryUrl).pipe(
      catchError((err) => this.handleError(err)),
      shareReplay()
    );
  }

  getThingUrl(thing: ThingDescription) {
    return of(`${this.thingUrl}/${thing.id}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(err: any) {
    this.logger.error('Error', err);
    return throwError(() => new Error(err));
  }
}
