<div class="debug-message debug-message--{{ message.type | debugMessageType | lowercase }}">
  <div class="meta">
    <div class="type">[{{ message.type | debugMessageType }}]</div>
    <div class="timestamp">{{ message.timestamp | date : 'HH:mm:ss.sss' }}</div>
    <div class="initiator">{{ message.initiator }}</div>
  </div>
  <div class="content">
    <div
      class="message"
      [ngClass]="{ 'is-expandable': message.parameters.length > 0 }"
      (click)="showParams = !showParams"
    >
      {{ message.formattedMessage }}
    </div>
    <div class="params" *ngIf="showParams && message.parameters.length > 0">
      <div class="param" *ngFor="let param of message.parameters">{{ param | json }}</div>
    </div>
  </div>
</div>
