import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { IconList, ICONS } from 'sick-base-theme';

@Component({
  selector: 'sick-modal',
  templateUrl: './sick-modal.component.html',
  styleUrls: ['./sick-modal.component.scss'],
})
export class SickModalComponent implements OnInit {
  @Input() title = '';
  @Output() close: EventEmitter<null> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(@Inject(ICONS) public icons: IconList) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}

  onCloseClicked() {
    this.close.emit();
  }
}
