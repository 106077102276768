import { Component, OnInit } from '@angular/core';

import { SickIdAuthService } from 'sick-id-auth';

@Component({
  selector: 'sick-login',
  templateUrl: './sick-login.component.html',
  styleUrls: ['./sick-login.component.scss'],
})
export class SickLoginComponent implements OnInit {
  constructor(private sickIdAuthService: SickIdAuthService) {}

  ngOnInit(): void {
    //
  }

  login() {
    this.sickIdAuthService.login();
  }
}
