import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { RuleInput, RulePublic, RuleUpdateInput } from 'models';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DataMonitoringApiService {
  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/rules', this.httpClient);
  }

  create(rule: RuleInput) {
    return this.baseApiService.create<RuleInput, RulePublic>(rule);
  }

  read(ruleId: string) {
    return this.baseApiService.read<RulePublic>(ruleId);
  }

  update(ruleId: string, rule: RuleUpdateInput) {
    return this.baseApiService.update<RuleUpdateInput, RulePublic>(ruleId, rule);
  }

  delete(ruleId: string) {
    return this.baseApiService.delete(ruleId);
  }

  list(instanceId: string) {
    return this.baseApiService.list<RulePublic>({ instanceId: instanceId });
  }
}
