import { JsonPipe, LocationStrategy, NgIf } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AuthInterceptor,
  AuthModule,
  LogLevel,
  OidcSecurityService,
  OpenIdConfiguration,
  StsConfigLoader,
  StsConfigStaticLoader,
} from 'angular-auth-oidc-client';

import { SickIdAuthCallbackComponent } from './sick-id-auth-callback/sick-id-auth-callback.component';
import { SickIdAuthComponent } from './sick-id-auth/sick-id-auth.component';

const routes: Routes = [{ path: 'auth-callback', component: SickIdAuthCallbackComponent }];

export type AuthConfig = Pick<
  OpenIdConfiguration,
  | 'authority'
  | 'clientId'
  | 'redirectUrl'
  | 'secureRoutes'
  | 'silentRenew'
  | 'silentRenewUrl'
  | 'renewTimeBeforeTokenExpiresInSeconds'
>;

export abstract class SickIdAuthConfigProvider {
  abstract getConfig(): AuthConfig;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(
    private locationStrategy: LocationStrategy,
    @Optional() private configProvider: SickIdAuthConfigProvider
  ) { }

  getConfig(): OpenIdConfiguration {
    const defaults: OpenIdConfiguration = {
      authority: 'https://id.sick.com/auth/realms/sickservices',
      redirectUrl: window.location.origin + this.locationStrategy.getBaseHref() + 'auth-callback',
      postLogoutRedirectUri: window.location.origin,
      clientId: 'IntegrationHub',
      scope: 'openid profile email',
      responseType: 'code',
      logLevel: LogLevel.Warn,
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      renewTimeBeforeTokenExpiresInSeconds: 60,
    };

    const config = this.configProvider ? { ...defaults, ...this.configProvider.getConfig() } : defaults;

    return config;
  }
}

const authFactory = (configService: ConfigService) => {
  const config = configService.getConfig();
  return new StsConfigStaticLoader(config);
};

@NgModule({
  declarations: [SickIdAuthComponent],
  imports: [
    RouterModule.forRoot(routes),
    JsonPipe,
    NgIf,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [ConfigService],
      },
    }),
  ],
  exports: [SickIdAuthComponent],
})
export class SickIdAuthModule {
  constructor(private oidcSecurityService: OidcSecurityService) { }

  static forRoot(): ModuleWithProviders<SickIdAuthModule> {
    return {
      ngModule: SickIdAuthModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    };
  }
}
