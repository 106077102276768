import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';

import { DataMapping, DataSourceType, InstanceInput, InstancePublic } from 'models';
import { InstanceManagerApiService } from 'sick-api';
import { IconList, ICONS } from 'sick-base-theme';
import { v4 as uuidv4 } from 'uuid';

interface FlmWizardForm {
  title: string;
  device: string;
  property: string;
  flow: string;
  min: string;
  max: string;
}

@Component({
  selector: 'app-instance-wizard',
  templateUrl: './instance-wizard.component.html',
  styleUrls: ['./instance-wizard.component.scss'],
})
export class InstanceWizardComponent {
  @Output() cancel = new EventEmitter();
  @Output() instanceCreated = new EventEmitter<InstancePublic>();
  @ViewChild('submitbtn', { read: ElementRef }) submitBtn: ElementRef | undefined;
  loading = false;
  instanceForm = this.formBuilder.group({
    title: new FormControl('', Validators.required),
    device: new FormControl('', Validators.required),
    property: new FormControl('', Validators.required),
    flow: new FormControl('', Validators.required),
    min: new FormControl('', Validators.required),
    max: new FormControl('', Validators.required),
  });

  constructor(
    private formBuilder: NonNullableFormBuilder,
    private instanceApi: InstanceManagerApiService,
    @Inject(ICONS) public icons: IconList
  ) {}

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    this.loading = true;
    const wizard = this.instanceForm.value as FlmWizardForm;

    const mappings = this.createDataMapping(wizard);

    const instance: InstanceInput = {
      title: 'Some title',
      config: {
        things: [
          {
            thingModel: 'MyModel',
            thingTitle: 'My title',
            dataMappings: [
              {
                dataSource: {
                  type: DataSourceType.LIVECONNECT,
                  property: 'PROP',
                  dataType: 'number',
                },
                dataTarget: {
                  property: 'MY_PROP',
                  dataType: 'number',
                  thingId: 'MY-THING-ID',
                },
                flow: {
                  flowId: 'MY_FLOW_ID',
                  params: {
                    min: '0',
                    max: '250',
                  },
                },
              },
            ],
          },
        ],
      },
      appTemplate: {
        id: 'my-template',
        title: 'app-template-title',
      },
    };

    const newInstance: InstanceInput = {
      ...instance,
      ...{
        title: wizard.title,
        config: {
          persistence: {
            magneticStoreRetentionPeriodInDays: 90,
            memoryStoreRetentionPeriodInHours: 24,
          },
          things: [
            {
              thingModel: 'MyModel',
              thingTitle: 'My title',
              dataMappings: mappings,
            },
          ],
        },
      },
    };
    this.instanceApi.create(newInstance).subscribe((instance) => {
      this.loading = false;
      this.instanceCreated.emit(instance);
    });
  }

  private createDataMapping(input: FlmWizardForm): DataMapping[] {
    const mappings: DataMapping[] = [
      {
        dataSource: {
          type: DataSourceType.LIVECONNECT,
          parameter: {
            deviceId: input.device,
            profileId: '4e25cddf-d47a-4188-a121-7671b231625c',
            // topic: `sick/device/presence-detection/data/${input.device}`,
            topic: `sick/device/presence-detection/data/fcd67def-830b-4091-a1a1-2703c3c6036f`,
          },
        },
        dataTarget: {
          thingId: uuidv4(),
          dataType: 'double',
          property: 'filllevel',
        },
        flow: {
          flowId: input.flow,
          params: {
            min: input.min,
            max: input.max,
          },
        },
      },
    ];

    return mappings;
  }
}
