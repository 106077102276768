import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CustomFunction, HeaderEvent, HeaderFunction } from 'sick-base-app';
import { IconList, ICONS } from 'sick-base-theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  customFunctions: CustomFunction[] = [
    // {
    //   id: HeaderFunction.SETTINGS,
    //   title: 'Settings',
    //   iconSrc: this.SICK_ICONS['SETTINGS'],
    // },
  ];

  constructor(private translateService: TranslateService, @Inject(ICONS) private SICK_ICONS: IconList) {
    this.translateService.setDefaultLang('en');
  }

  async onButtonClicked(event: HeaderEvent) {
    if (event.detail?.id === HeaderFunction.SETTINGS) {
      // alert('You do not have the required permissions to manage the instances.');
    }
  }
}
