/* eslint-disable @typescript-eslint/no-explicit-any */
export enum DebugMessageType {
  NONE,
  ERROR,
  WARN,
  INFO,
  DEBUG,
}

export interface DebugMessage {
  initiator: string;
  timestamp: Date;
  message: string;
  formattedMessage: string;
  parameters: any[];
  type: DebugMessageType;
}
