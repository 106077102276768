import { Component, Inject } from '@angular/core';

import { IconList, ICONS } from 'sick-base-theme';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  showWizard = false;
  value = 0;
  allowCreation = false;

  constructor(@Inject(ICONS) public icons: IconList) {}

  onInstanceCreated() {
    this.showWizard = false;
  }
}
