/* eslint-disable no-console */
import { Environment } from 'sick-environment';

import { DebugMessage, DebugMessageType } from '../models/debug-message';
import { DebugWriter } from '../tokens/debug-output.token';

export class SickConsoleLogger implements DebugWriter {
  constructor(private env: Environment) {}

  write(message: DebugMessage) {
    if (message.type <= this.env.LOG_LEVEL) {
      switch (message.type) {
        case DebugMessageType.DEBUG:
          console.log(`[${message.initiator}]`, message.formattedMessage, message.parameters);
          break;
        case DebugMessageType.INFO:
          console.info(`[${message.initiator}]`, message.formattedMessage, message.parameters);
          break;
        case DebugMessageType.WARN:
          console.warn(`[${message.initiator}]`, message.formattedMessage, message.parameters);
          break;
        case DebugMessageType.ERROR:
          console.error(`[${message.initiator}]`, message.formattedMessage, message.parameters);
          break;
        default:
          break;
      }
    }
  }
}
